<template>
    <front-layout>
        <v-img v-if="isMobile" :src="project.image_url" class="text-right main-img">
            <v-btn class="transparent mt-4 mr-4 btn-favorite" depressed>
                <v-icon large class="color-icon-white">mdi-heart-outline</v-icon>
                <v-icon v-if="false" large class="color-icon-white">mdi-heart</v-icon>
            </v-btn>

            <v-btn class="mt-4 mr-4 btn-phone pa-0" width="38" height="38" max-height="38" max-width="38">
                <v-icon class="main_color_text">mdi-phone</v-icon>
            </v-btn>
        </v-img>

        <p v-if="loader" class="project-block__loader">Загрузка...</p>
        <v-container v-else-if="!project.id" style="min-height: calc(100vh - 228px)">
            <h1>Данный проект не найден!</h1>
        </v-container>
        <v-container v-else-if="project.id" fluid>
            <v-container>
                <v-row>
                    <v-col v-if="!isMobile" xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
                        <v-img :src="project.image_url" class="text-right main-img">
                            <v-btn class="transparent mt-4 mr-4 btn-favorite" depressed>
                                <v-icon large class="color-icon-white" :disabled="favoriteLoading" @click="setFavorite">
                                    {{ favorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                                </v-icon>
                            </v-btn>

                            <v-btn class="mt-4 mr-4 btn-phone pa-0" width="38" height="38" max-height="38"
                                max-width="38">
                                <v-icon class="main_color_text">mdi-phone</v-icon>
                            </v-btn>
                        </v-img>
                    </v-col>

                    <v-col xs="12" sm="12" md="12" lg="8" xl="8" cols="12" class="pl-0 pl-md-6 pr-0 pr-md-6">
                        <h2 class="main_color_text">{{ project.name }}</h2>
                        <p class="font-weight-bold fz-18 mb-3">{{ projectAddress }}</p>

                        <open-street-map id="project-map" class="mb-3" height="177" :lat="project.latitude"
                            :lng="project.longitude" :zoom="17" />
                        <!-- <v-btn class="main_color_text transparent mr-1 ways-to-buy fz-18 font-weight-bold px-2" depressed>
                            {{ $t('headers.btn_purchase_methods') }}
                        </v-btn> -->
                    </v-col>
                </v-row>

                <v-row class="pt-4">
                    <v-col md="9" class="pa-0">
                        <!-- Slider for project -->
                        <slider-for-project :data_slider="project.photos" />

                        <!-- Map path home for project -->
                        <MapPathHomeForProject :data_project="project" :project_address="projectAddress" />

                        <!-- Mortgage Calculator -->
                        <v-row class="mb-4 mt-4">
                            <v-col>
                                <v-col class="d-flex w-100 bg_white">
                                    <v-subheader class="main_color_text text-h6 font-weight-bold pl-0 ml-6">
                                        {{ $t('headers.mortgage_calculator') }}
                                    </v-subheader>
                                </v-col>

                                <v-col class="d-flex w-100 bg_white">
                                    <div
                                        class="subtitle_input main_color_text mt-3 ml-3 width_name_calc font_size_calc">
                                        {{ $t('headers.apartment_price') }}:</div>
                                    <v-text-field v-model="amounts" v-on:input="formatAmounts()"
                                        class="main_color_text filter_submit_btn text-capitalize subtitle-1 text_field_calc"
                                        solo></v-text-field>
                                    <div class="subtitle_input main_color_text mt-3 ml-2 font_size_calc">{{
                                            $t('headers.rub')
                                    }}.</div>
                                </v-col>
                                <v-col class="d-flex w-100 bg_white">
                                    <div
                                        class="subtitle_input main_color_text  mt-3 ml-3 width_name_calc font_size_calc">
                                        {{ $t('headers.initial_fee') }}:</div>
                                    <v-text-field v-model="initial_fee" v-on:input="formatFee()"
                                        class="main_color_text filter_submit_btn text-capitalize subtitle-1 text_field_calc"
                                        solo></v-text-field>
                                    <div class="subtitle_input main_color_text mt-3 ml-2 font_size_calc">{{
                                            $t('headers.rub')
                                    }}.</div>
                                </v-col>
                                <v-col class="d-flex w-100 bg_white">
                                    <div
                                        class="subtitle_input main_color_text mt-3 ml-3 width_name_calc font_size_calc">
                                        {{ $t('headers.mortgage_term') }}:</div>
                                    <v-text-field v-model="mortgage_term" v-on:input="formatMortgage()"
                                        class="main_color_text filter_submit_btn text-capitalize subtitle-1 text_field_calc"
                                        solo></v-text-field>
                                    <div class="subtitle_input main_color_text  mt-3 ml-2 font_size_calc">{{
                                            $t('headers.years')
                                    }}</div>
                                </v-col>
                                <v-col class="d-flex w-100 bg_white">
                                    <div
                                        class="subtitle_input main_color_text mt-3 ml-3 width_name_calc font_size_calc">
                                        {{ $t('headers.interest_rate') }}:</div>
                                    <v-text-field v-model="interest_rate" v-on:input="formatInterest()"
                                        class="main_color_text filter_submit_btn text-capitalize subtitle-1 text_field_calc"
                                        solo></v-text-field>
                                    <div class="subtitle_input main_color_text mt-3 ml-2 font_size_calc">{{
                                            $t('headers.interest_per_year')
                                    }}</div>
                                </v-col>
                                <v-col class="d-flex w-100 bg_white">
                                    <v-btn
                                        class="main_color_text filter_submit_btn text-capitalize subtitle-1 font-weight-bold mx-auto"
                                        @click="calculateMortgage(amounts, initial_fee, mortgage_term, interest_rate)"
                                        depressed>{{ $t('headers.calculate') }}
                                    </v-btn>
                                </v-col>

                                <div v-if="(month_pay && over_costs && general_costs)">
                                    <v-col class="d-flex w-100 bg_white">
                                        <div
                                            class="subtitle_input main_color_text mt-3 ml-3 width_name_calc font_size_calc">
                                            {{ $t('headers.month_pay') }}: </div>
                                        <div class=" text_field_result_calc">
                                            <div class="mt-3 ml-2">{{ month_pay }}</div>
                                        </div>
                                        <div class="subtitle_input main_color_text mt-3 ml-2 font_size_calc">{{
                                                $t('headers.rub')
                                        }}.</div>
                                    </v-col>
                                    <v-col class="d-flex w-100 bg_white">
                                        <div
                                            class="subtitle_input main_color_text mt-3 ml-3 width_name_calc font_size_calc">
                                            {{ $t('headers.general_costs') }}: </div>
                                        <div class=" text_field_result_calc">
                                            <div class="mt-3 ml-2">{{ general_costs }}</div>
                                        </div>
                                        <div class="subtitle_input main_color_text mt-3 ml-2 font_size_calc">{{
                                                $t('headers.rub')
                                        }}.</div>
                                    </v-col>
                                    <v-col class="d-flex w-100 bg_white">
                                        <div
                                            class="subtitle_input main_color_text mt-3 ml-3 width_name_calc font_size_calc">
                                            {{ $t('headers.over_costs') }}: </div>
                                        <div class=" text_field_result_calc">
                                            <div class="mt-3 ml-2">{{ over_costs }}</div>
                                        </div>
                                        <div class="subtitle_input main_color_text mt-3 ml-2 font_size_calc">{{
                                                $t('headers.rub')
                                        }}.</div>
                                    </v-col>

                                </div>
                                <div v-else-if="(month_pay && over_costs && general_costs) < 0">
                                    <v-col class="d-flex w-100 bg_white">
                                        <div class="mx-auto main_color_text">{{ $t('headers.wrong_data') }}!</div>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>

                        <!-- Bank offers -->
                        <v-col class="d-flex w-100 ml-10">
                            <v-subheader class="main_color_text text-h6 font-weight-bold pl-0 ml-6">
                                {{ $t('headers.offers_from_banks') }}
                            </v-subheader>
                        </v-col>
                        <v-expansion-panels v-for="item in bank.slice(0, bank_qty)" :key="item.id">
                            <bank-offers-card :bank_data="item" />
                        </v-expansion-panels>
                        <v-row v-if="bank_qty <= bank.length">
                            <v-col class="d-flex justify-center mb-4">
                                <v-btn class="transparent text-transform-none main_color_text font-weight-bold fz-18"
                                    depressed @click="bank_qty += 3">
                                    Ещё 3 предложения
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col md="3">
                        <advertising :limit="3" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12"
                        class="order-2 order-md-1 py-0 pl-0 pr-0 pr-lg-4">
                        <v-sheet v-if="project.how_to_buy" class="pa-3 pa-lg-4 mb-3 mb-md-4">
                            <h3 class="mb-1 mb-lg-4 main_color_text">{{ $t('headers.how_to_buy') }}</h3>
                            <div class="pa-0 mb-n3 mb-lg-n1 text-pre-wrap line-height32">{{ project.how_to_buy }}</div>
                        </v-sheet>
                        <v-sheet class="main_color_text pa-4">
                            <h3 class="main_color _text mb-3 mb-md-4">{{ $t('headers.legal_documents') }}</h3>
                            <v-list-item class="pa-0 main_color_text mb-md-1 mb-0"> Проектная декларация -ից 08.04.2020
                                г.
                                <a href="#" download>PDF 632 KB</a>
                            </v-list-item>
                            <v-list-item class="pa-0 main_color_text mb-md-1 mb-0">Разрешение на строительство до
                                20.05.2023 г.
                                <a href="#" download>PDF 206 KB</a>
                            </v-list-item>
                            <v-list-item class="pa-0 main_color_text mb-md-1 mb-0">Проектная декларация -ից 07.05.2021
                                г.
                                <a href="#" download>PDF 742 KB</a>
                            </v-list-item>
                        </v-sheet>
                    </v-col>
                    <v-col xs="12" sm="12" md="12" lg="8" xl="8" cols="12"
                        class="mt-2 mt-lg-0 pt-2 pt-lg-0 px-0 order-1 order-md-2">
                        <v-sheet class="main_color_text pa-4">
                            <h3 class="main_color_text mb-3 mb-md-4">{{ $t('headers.specifications') }}</h3>
                            <v-list>
                                <v-list-item class="px-0 mb-sm-0 mb-3 text-left d-flex align-start">
                                    <span class="w-300">{{ $t('headers.developer') }}</span>
                                    <span v-if="project.developer" class="main_color_text text-left">
                                        {{ project.developer.name }}
                                    </span>
                                </v-list-item>

                                <template v-if="$_.isObject(project.characteristics)">
                                    <template v-for="(char, idx) in characteristics">
                                        <v-list-item v-if="project.characteristics[char]" :key="idx"
                                            class="px-0 pr-0 mb-sm-0 mb-3 pt-0 d-flex align-start">
                                            <span class="w-300 ">{{ $t('headers.' + char) }}</span>
                                            <span class="max-width45 mt-0 pr-0 max-width100 main_color_text text-left">
                                                {{ project.characteristics[char] }}
                                            </span>
                                        </v-list-item>
                                    </template>
                                </template>
                            </v-list>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-row v-if="project.description">
                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="pa-0 mt-3 mt-sm-4 mt-md-1">
                        <v-card class="" elevation="0">
                            <v-card-title class="main_color_text font-weight-bold mb-4">
                                {{ $t('headers.detailed_information') }}
                            </v-card-title>
                            <v-card-text class="line-height32">{{ project.description }}</v-card-text>
                            <span class="py-2 px-4 d-block mb-1">
                                Способы покупки: <b>ипотека, рассрочка, trade-in, материнский капитал и субсидии.</b>
                            </span>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <v-container v-if="project.id" fluid>
            <v-container>
                <v-row>
                    <v-col xs="12" sm="12" md="12" lg="9" xl="9" cols="12">
                        <v-row>
                            <v-col>
                                <h3 class="main_color_text font-weight-bold mb-4 text-h5">
                                    {{ $t('headers.reviews') }}
                                </h3>
                                <template v-if="project.reviews">
                                    <div v-for="review in project.reviews" :key="'review' + review.id">
                                        <comments-card :comment_data="review" />
                                    </div>
                                </template>
                            </v-col>
                        </v-row>

                        <!-- Review -->
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="px-0 px-md-2">
                                <h3 class="main_color_text font-weight-bold mb-4 text-h6">
                                    {{ $t('headers.leave_feedback') }}
                                </h3>
                                <comment-form :reviewable_type="'App\\Models\\Project'" :reviewable_id="project.id"
                                    @update="updateProject" />
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col v-if="!isMobile" xs="12" sm="12" md="12" lg="3" xl="3" cols="12">
                        <appointment-viewing-aside-card :project="project" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <v-container v-if="project.id" fluid class="overflow-hidden">
            <v-container>
                <v-row>
                    <v-col>
                        <h3 class="main_color_text font-weight-bold mb-4 mb-md-0 text-h5">
                            {{ $t('headers.similar_offers') }}
                        </h3>
                    </v-col>
                </v-row>

                <v-row class="text-center">
                    <v-col v-if="isMobile" cols="12" sm="12" md="6" lg="4" xl="4" class="mt-6 mt-md-0">
                        <v-carousel hide-delimiter-background :show-arrows="false" :continuous="false" height="600"
                            reverse-transition="fade-transition" transition="fade-transition"
                            class="carousel-circle align-content-stretch d-flex">
                            <v-carousel-item v-for="item in data_similar_offers_card" :key="`developer` + item[0].id"
                                :active-class="'active---class'">
                                <similar-offers-card :project="item[0]" class="d-flex flex-column" @change="init" />
                                <similar-offers-card :project="item[1]" class="d-flex flex-column" @change="init" />
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                    <v-col v-else cols="12" sm="12" md="6" lg="3" xl="3" class="align-content-stretch d-flex"
                        v-for="item in projects.slice(0, 4)" :key="'similar-offers-card' + item.id">
                        <similar-offers-card :project="item" class="d-flex flex-column" @change="init" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <v-container class="d-none">
            <v-row class="text-center">
                <v-col cols="12">
                    <h1>{{ project.name }}</h1>
                    <div>
                        {{ apartmentsNumber }} apartments from {{ minPrice }}
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="12" lg="6" cols="12">
                </v-col>
                <v-col md="12" lg="6" cols="12">
                    <div class="mb-5">
                        {{ project.description }}
                    </div>
                    <div>
                        <lead-dialog :project_id="project.id" :developer_id="project.developer_id" />
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="12" lg="6" cols="12">
                    <div v-for="(apartments, bedrooms) in project.groupped_apartments" :key="bedrooms">
                        <div>
                            <h3>{{ bedrooms }} Bedrooms</h3>
                        </div>
                        <div>
                            <v-data-table :headers="headers" :items="apartments" :items-per-page="5"
                                class="elevation-1" />
                        </div>
                    </div>
                </v-col>
                <v-col md="12" lg="6" cols="12">
                    <developer-card v-if="project.developer" :developer="project.developer" />
                </v-col>
            </v-row>
        </v-container>

        <auth-dialog v-model="favoriteDialog" />
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import Project from "@/models/Project";
import OpenStreetMap from "@/components/OpenStreetMap";
import DeveloperCard from "@/components/cards/DeveloperCard";
import LeadDialog from "@/components/dialogs/LeadDialog";
import SimilarOffersCard from "@/components/cards/SimilarOffersCard";
import BankOffersCard from "@/components/cards/BankOffersCard";
import CommentsCard from "@/components/cards/CommentsCard";
import AppointmentViewingAsideCard from "@/components/cards/AppointmentViewingAsideCard";
import CommentForm from "@/components/forms/CommentForm";
import SliderForProject from "@/components/SliderForProject";
import MapPathHomeForProject from "@/components/MapPathHomeForProject";
import Bank from "@/models/Bank";
import axios from 'axios';
import i18n from "@/i18n";
import AuthDialog from "@/components/dialogs/AuthDialog";
import Api from "@/api/Api";

export default {
    name: "projects.show",
    components: {
        AuthDialog,
        LeadDialog,
        OpenStreetMap,
        FrontLayout,
        SimilarOffersCard,
        BankOffersCard,
        CommentsCard,
        CommentForm,
        AppointmentViewingAsideCard,
        SliderForProject,
        MapPathHomeForProject,
        DeveloperCard,
        Advertising: () => import('@/components/ads/Advertising'),
    },

    computed: {
        apartmentsNumber() {
            return this.project.apartments ? this.project.apartments.length : ''
        },
        minPrice() {
            let cheapestApartment = this.$_.minBy(this.project.apartments, 'price');
            return cheapestApartment ? cheapestApartment.price : ''
        },
        bedroomsNumberList() {
            const array = this.$_.uniqBy(this.project.apartments, 'bedrooms')
                .map(apartment => apartment.bedrooms).sort()
            array.push(array.splice(array.indexOf(0), 1)[0])
            return array
        },
        apartmentsArrays() {
            const apartmentsArrays = []
            this.bedroomsNumberList.forEach(apartmentType => {
                apartmentsArrays.push(this.filterByBedrooms(apartmentType))
            })
            return apartmentsArrays
        },
        favorite() {
            const projectId = this.project.id
            return (this.$auth.user()?.favorites ?? [])
                .find(({ favoritable_type, favoritable }) => favoritable_type === 'App\\Models\\Project' && favoritable?.id === projectId);
        },
        isMobile() {
            return this.$vuetify.breakpoint.width < 900
        }
    },

    data() {
        return {
            project: { developer: null, apartments: [] },
            headers: [
                {
                    text: 'Bedrooms',
                    align: 'start',
                    sortable: false,
                    value: 'bedrooms',
                },
                { text: 'Floor', value: 'floor' },
                { text: 'Area', value: 'area' },
                { text: 'Price', value: 'price' },
            ],
            list_img: [],
            items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
            rating: null,
            indigo: '#3F44C3',
            green: '#42B261',
            yellow: '#D6A700',
            bank: [],
            bank_qty: 3,
            data_similar_offers_card: [],
            projects: [],
            loader: false,
            amounts: '2 500 000',
            initial_fee: '500 000',
            mortgage_term: '25',
            interest_rate: '4',
            month_pay: '',
            over_costs: '',
            general_costs: '',
            projectAddress: '',
            characteristics: [
                "deadline",
                "building_type",
                "number_of_storeys",
                "ceiling_height",
                "parking",
                "new_building_class",
                "rating",
                "stage_of_construction",
            ],
            favoriteDialog: false,
            favoriteLoading: false,
            project_name: ''
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.project_with_name_title', {project_name: this.project_name}),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta.project_with_name_description', {project_name: this.project_name})
                }
            ]
        }
    },
    async mounted() {
        await this.init()
        await this.fetchProjectAddress()
    },

    methods: {
        handleClear() {
            this.complex = null
            this.selectedRooms = null
            this.selectedYear = null
            this.slider_term = 0
            this.price = ''
            this.initialPayment = ''
        },
        async init() {
            this.loader = true

            let slug = this.$route.params.project
            if (slug) {
                this.project = await Project.custom('/projects/slug/' + slug).first()

                this.setProjectName()

                this.project.groupped_apartments = this.$_.groupBy(this.project.apartments, 'bedrooms')

                this.bank = await Bank.get()

                this.loader = false
            }
            this.bank = await Bank.get()

            this.projects = await Project.get()
            this.projects = this.projects.slice(0, 10)
            let arr_sale_card = this.projects.slice(0, 10)
            this.data_similar_offers_card = this.chunkArray(arr_sale_card, 2)

            this.loader = false
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        setProjectName(){
          if(this.$i18n.locale === 'ru'){
              this.project_name = this.project.name_ru
          }else if(this.$i18n.locale === 'en'){
              this.project_name = this.project.name_en
          }else {
              this.project_name = this.project.name_hy
          }
        },
        updateProject(review) {
            this.project.reviews.push(review)
        },
        //sum, fee, period, rate
        calculateMortgage(...args) {
            let [sum, fee, period, rate] = args.map ( str => this.stringToInt(str))

            let i, kf, month_pay, general_costs, over_price;

            sum = sum - fee

            // ставка в месяц
            i = (rate / 12) / 100;

            // коэффициент аннуитета
            kf = (i * (Math.pow(1 + i, period * 12))) / (Math.pow(1 + i, period * 12) - 1);

            // итог
            month_pay = sum * kf;
            general_costs = period * (month_pay * 12)
            over_price = general_costs - sum

            if (month_pay && general_costs && over_price) {
                // округлим до целых
                this.month_pay = this.formatString(month_pay);
                this.over_costs = this.formatString(over_price);
                this.general_costs = this.formatString(general_costs);
            } else {
                this.month_pay = 'Error';
                this.over_costs = 'Error';
                this.general_costs = 'Error';
            }

        },
        formatFee() {
            this.initial_fee = this.formatString(this.initial_fee)
        },
        formatAmounts() {
            this.amounts = this.formatString(this.amounts)
        },
        formatMortgage() {
            this.mortgage_term = this.formatString(this.mortgage_term);
        },
        formatInterest() {
            this.interest_rate =  this.formatString(this.interest_rate);
        },
        formatString(str) {
            if(typeof(str) === 'number') str = str.toFixed()
            return (this.stringToInt(str) + '').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
        },
        stringToInt(str) {
            return parseInt(str.split(' ').join(''))
        },
        fetchProjectAddress() {
            return axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.project.latitude}&lon=${this.project.longitude}&accept-language=${i18n.locale}`, {
                withCredentials: false
            })
                .then(response => {
                    this.projectAddress = response.data.display_name
                })
                .catch(() => {
                    this.projectAddress = "This information is not available, please try back later"
                })
        },
        filterByBedrooms(bedroomsNumber) {
            return this.project.apartments.filter(apartment => apartment.bedrooms === bedroomsNumber)
        },
        minSize(apartments) {
            return Math.min(...apartments.map(apartment => apartment.area))
        },
        maxSize(apartments) {
            return Math.max(...apartments.map(apartment => apartment.area))
        },
        minPriceByBedrooms(apartments) {
            return Math.min(...apartments.map(apartment => apartment.price))
        },
        setFavorite() {
            if (this.favoriteLoading) return;

            if (!this.$auth.user()) {
                this.favoriteDialog = true;

                return;
            }

            this.favoriteHandler();
        },
        async favoriteHandler() {
            let slug = this.$route.params.project;
            if (!slug) return;
            this.favoriteLoading = true;

            const data = await Api.favoriteProject(slug)
                .then(res => res.data)
                .then(data => data)
                .catch(null);
            this.favoriteLoading = false;
            if (!data) return;

            const user = this.$auth.user();
            let favorites = user?.favorites ?? [];

            if (this.favorite) {
                const projectId = this.project.id;
                favorites = favorites
                    .filter(({ favoritable_type, favoritable }) => !(favoritable_type === 'App\\Models\\Project' && favoritable?.id === projectId))
            } else {
                favorites.push(data);
            }
            user.favorites = favorites;
            this.$auth.user(user);
        },
    }
}

</script>

<style lang="scss" scoped>
.project-block {
    &__loader {
        text-align: center;
        color: rgb(17, 151, 246);
    }
}

.ways-to-buy {
    height: 50px !important;
    width: auto !important;
    border-radius: 0;
    text-transform: none;

    @media all and (max-width: 900px) {
        display: block;
        margin: 20px auto !important;
        width: 100% !important;
    }
}

/* Carousel images */
.main-img {
    margin-left: -116px;
    width: 500px;
    height: 546px;
    min-width: 500px;
    min-height: 546px;
    position: relative;

    @media all and (max-width: 1240px) {
        margin-left: 0;
        width: auto;
        height: auto;
        min-width: auto;
        min-height: auto;
    }

    @media all and (max-width: 900px) {
        height: 450px;
        min-height: 450px;
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }

    .btn-phone,
    .btn-favorite {
        max-width: 38px !important;
        min-width: 38px !important;
        max-height: 38px !important;
        min-height: 38px !important;
        padding: 0 !important;
        border-radius: 0;
        position: absolute;
        right: 15px;
        top: 10px;
    }

    .btn-favorite {
        position: absolute;
        right: 75px;
        top: 10px;
    }
}

// Purchase methods
.bg_violet {
    background: #8e91d9 !important;
}

.btn_aside_action {
    min-width: 100% !important;
}

.fz-18 {
    font-size: 18px;
    letter-spacing: 0;
}

.fz-14 {
    font-size: 14px;
    letter-spacing: 0;
}

.lh-22 {
    line-height: 22px;
}

.w-300 {
    width: 300px;
    min-width: 300px;

    @media all and (max-width: 900px) {
        width: 150px;
        min-width: 150px;
    }
}

.line-height32 {
    line-height: 32px;
}

.btn-percent {
    position: absolute;
    background: var(--green_color-base) !important;
    bottom: 10px;
    right: 0;
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
}

.card-plan_bg-violet {
    background: rgba(63, 68, 196, 0.1);
    min-height: 37px !important;
    padding: 0 4px !important;
}

.v-rating button.mdi-star {
    width: 29px !important;
    height: 29px !important;
    padding: 0 !important;
}

.w-100 {
    width: 100% !important;
}

.min-w280 {
    min-width: 280px;
}

.wrap_inp {
    position: relative;

    .subtitle_input {
        position: absolute;
        color: white;
        top: -25px;
        left: 0;
        font-size: 16px;
    }
}

.white-space-nowrap {
    white-space: nowrap;
}

.color-icon-white {
    color: white !important;
}

.btn-phone,
.btn-favorite {
    max-width: 38px !important;
    min-width: 38px !important;
    max-height: 38px !important;
    min-height: 38px !important;
    padding: 0 !important;
    border-radius: 0;
}

.btn-fixed-price,
.build_a_route {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    height: 50px !important;
    width: 294px;
    border-radius: 0;
    text-transform: none;
}

.btn_carousel {
    border-radius: 0;
    width: 100%;
    text-align: left !important;
    color: #000000;
    background: rgba(63, 68, 195, .1) !important;
    text-transform: none;
    font-size: 18px;
    height: 62px !important;
    padding-left: 23px !important;
    letter-spacing: 0;
    margin-bottom: 10px;

    span.v-btn__content {
        max-width: 100% !important;
    }

    @media all and (max-width: 1240px) {
        height: 45px !important;
    }
}

#active {
    background: #3F44C3 !important;
    color: #ffffff;
}

.img_small {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: -10px;
    margin-left: -10px;

    button {
        margin: 10px 0 0 10px;
    }
}

// Infrastructure
.wrap_radio {
    width: 250px;

    .v-input--radio-group__input {
        display: flex !important;
        justify-content: space-between !important;
    }
}

.path-home_check {
    border-radius: 0;
    width: 100%;
    color: #000000 !important;
    background: rgba(63, 68, 195, .1) !important;
    text-transform: none;
    font-size: 18px;
    height: 62px !important;
    padding-left: 10px !important;
    letter-spacing: 0;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 0;
    display: flex;
    align-items: flex-end;

    @media all and (max-width: 1240px) {
        height: 45px !important;
    }

    .v-input__control {
        .v-input__slot {
            margin-bottom: 15px !important;
        }
    }
}

.path-home_check .v-input__control .v-input__slot {
    margin-bottom: 15px !important;
}



label.v-label {
    font-size: 14px !important;
}

.color-icon-main,
#color-icon-main {
    color: var(--main_color-base) !important;
}

.btn_aside_action {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.min-w250 {
    min-width: 250px;
}

@media all and (max-width: 1240px) {
    .min-width100 {
        min-width: 100%;
    }

    .max-width100 {
        max-width: 100%;
    }
}

.text--white {
    color: white;
}

.select-status {
    @media all and (max-width: 770px) {
        display: inline-block;
    }
}

.w-310 {
    width: 310px;

    @media all and (max-width: 1240px) {
        width: 100%;
        min-width: 100%;
    }
}

.max-width45 {
    @media all and (max-width: 1240px) {
        max-width: 45%;
    }
}

.w-156 {
    width: 156px;
}

.filter_submit_btn {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.slider_term_text_field {
    position: relative;
    height: 48px;
    color: var(--main_color-base) !important;
}

.slider_term {
    color: var(--main_color-base) !important;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 350px;
}

.white_space_nowrap {
    white-space: nowrap;
}

.min-height20 {
    min-height: 20px;
}

.input_checkbox {

    .v-input--selection-controls__ripple,
    .mdi-checkbox-blank-outline,
    .mdi-checkbox-marked {
        width: 20px;
        height: 20px;
        max-width: 20px;
        max-height: 20px;
        font-size: 14px;
        transform: translateX(10px);
    }
}

.btn-fixed-price {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    height: 62px !important;
    width: 295px;
    border-radius: 0;
    text-transform: none;

    @media all and (max-width: 900px) {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
}

.width_name_calc {
    width: 205px
}

.font_size_calc {
    font-size: medium
}

.text_field_calc {
    max-width: 30%;
    border: 1px solid blue;
    margin-left: 100px
}

.text_field_result_calc {
    border: 1px solid blue;
    margin-left: 100px;
    width: 275px;
    height: 50px
}

.bg_white {
    background: #fff;
}
</style>
