<template>
    <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
            <v-sheet class="pa-md-5 pa-2">
                <v-row class="d-flex flex-wrap">
                    <v-col class="d-flex flex-column flex-md-wrap justify-space-between min-width100">
                        <h2 class="main_color_text font-weight-bold mb-3">{{ $t('headers.infrastructure') }}</h2>
                        <p class="font-weight-bold mb-3">{{ project_address }}</p>

                        <v-card
                            v-if="isMobile"
                            class="transparent d-flex justify-space-between align-baseline flex-wrap"
                            elevation="0" rounded="0"
                        >
                            <v-checkbox
                                v-model="show.kindergarten"
                                :label="$t('headers.kindergartens')"
                                class="font-weight-regular path-home_check_mob justify-center align-center mt-0 black-color-text pt-1 pr-3"
                                @click="handleAmenities(show.kindergarten, 'kindergarten')"
                            />
                            <v-checkbox
                                v-model="show.school"
                                :label="$t('headers.schools')"
                                class="font-weight-regular path-home_check_mob justify-center align-center mt-0 black-color-text pt-1 pr-3"
                                @click="handleAmenities(show.school, 'school')"
                            />
                            <v-checkbox
                                v-model="show.university"
                                :label="$t('headers.higher_education_institutions')"
                                class="font-weight-regular path-home_check_mob justify-center align-center mt-0 black-color-text pt-1 pr-3"
                                @click="handleAmenities(show.university, 'university')"
                            />
                            <v-checkbox
                                v-model="show.medicine"
                                :label="$t('headers.medicine')"
                                class="font-weight-regular path-home_check_mob justify-center align-center mt-0 black-color-text pt-1 pr-3"
                                @click="handleAmenities(show.medicine, 'clinic|hospital|dentist')"
                            />
                            <v-checkbox
                                v-model="show.restaurant"
                                :label="$t('headers.restaurants')"
                                class="font-weight-regular path-home_check_mob justify-center align-center mt-0 black-color-text pt-1 pr-3"
                                @click="handleAmenities(show.restaurant, 'restaurant')"
                            />
                            <v-checkbox
                                v-model="show.mall"
                                :label="$t('headers.shopping_centers')"
                                class="font-weight-regular path-home_check_mob justify-center align-center mt-0 black-color-text pt-1 pr-3"
                                @click="handleAmenities(show.mall, 'marketplace')"
                            />
                            <v-checkbox
                                v-model="show.mfc"
                                :label="$t('headers.multifunctional_center')"
                                class="font-weight-regular path-home_check_mob justify-center align-center mt-0 black-color-text pt-1 pr-3"
                                @click="handleAmenities(show.mfc, 'townhall')"
                            />
                        </v-card>

                        <open-street-autocomplete
                            :submit-disabled="!routeToIsReady"
                            @updated="setRouteTo"
                            @create-route="handleCreateRoute"
                        />

                        <!--<vuetify-google-autocomplete
                            ref="address"
                            id="map"
                            class="main_color_text rounded-0 pa-0"
                            :class="isMobile ? 'enter_work_address_mob ma-0' : 'enter_work_address mr-md-3 mr-0 mt-0'"
                            classname="form-control"
                            :placeholder="$t('headers.enter_work_address')"
                            country="am"
                            @placechanged="getAddressData"
                        >
                            <template v-if="isMobile" #append>
                                <v-btn
                                    :disabled="!routeToIsReady"
                                    depressed
                                    round="0"
                                    class="btn_arrow_right"
                                    @click="handleCreateRoute"
                                >
                                    <v-icon>mdi-arrow-right</v-icon>
                                </v-btn>
                            </template>
                        </vuetify-google-autocomplete>-->
                    </v-col>

                    <v-col class="d-flex flex-column justify-space-between flex-wrap max-width280 min-width100">
                        <v-radio-group
                            v-model="typeWayToGet"
                            class="d-flex justify-space-between wrap_radio mt-0 min-width100 mb-3 mb-md-0"
                            row dense hide-details
                            @change="handleCreateRoute"
                        >
                            <v-radio
                                :label="$t('headers.checkbox_on_foot')"
                                active-class="font-weight-bold"
                                value="walking"
                                class="main_color_text"
                                off-icon="'mdi-radiobox-marked', 'mdi-radiobox-blank'"
                            />
                            <v-radio
                                :label="$t('headers.checkbox_on_transport')"
                                active-class="font-weight-bold"
                                value="driving"
                                class="main_color_text mr-0"
                                off-icon="'mdi-radiobox-marked', 'mdi-radiobox-blank'"
                            />
                        </v-radio-group>

                        <div class="d-flex justify-space-between max-width100">
                            <span class="main_color_text">
                                {{ $t('headers.travel_time') }}
                            </span>
                            <span class="main_color_text font-weight-bold">
                                {{ this.hours }}{{ $t('headers.hour') }} {{ this.minutes }}{{ $t('headers.minutes') }}
                            </span>
                        </div>

                        <v-btn
                            v-if="!isMobile"
                            :disabled="!routeToIsReady"
                            depressed
                            class="main_color_text build_a_route subtitle-1 font-weight-bold px-2 max-width100"
                            @click="handleCreateRoute"
                        >
                            {{ $t('headers.build_route') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col xs="12" sm="12" md="12" lg="7" xl="7" cols="12" class="pt-0">
                        <open-street-map
                            id="path-home-map"
                            ref="detailsMap"
                            :height="path_home_map_height"
                            :lat="data_project.latitude"
                            :lng="data_project.longitude"
                            :zoom="15"
                            :type-way="typeWayToGet"
                            class="mb-2 mt-4 mt-md-0"
                            @update-way-time="updateWayTime"
                        />
                    </v-col>

                    <v-col
                        v-if="!isMobile"
                        xs="12" sm="12" md="12" lg="5" xl="5" cols="12" class="pt-0"
                    >
                        <v-card class="transparent d-flex align-stretch flex-column" elevation="0" rounded="0">
                            <v-checkbox
                                v-model="show.kindergarten"
                                :label="$t('headers.kindergartens')"
                                class="font-weight-regular path-home_check justify-center align-center mt-0 black-color-text pt-1"
                                @click="handleAmenities(show.kindergarten, 'kindergarten')"
                            />
                            <v-checkbox
                                v-model="show.school"
                                :label="$t('headers.schools')"
                                class="font-weight-regular path-home_check justify-center align-center mt-0 black-color-text pt-1"
                                @click="handleAmenities(show.school, 'school')"
                            />
                            <v-checkbox
                                v-model="show.university"
                                :label="$t('headers.higher_education_institutions')"
                                class="font-weight-regular path-home_check justify-center align-center mt-0 black-color-text pt-1"
                                @click="handleAmenities(show.university, 'university')"
                            />
                            <v-checkbox
                                v-model="show.medicine"
                                :label="$t('headers.medicine')"
                                class="font-weight-regular path-home_check justify-center align-center mt-0 black-color-text pt-1"
                                @click="handleAmenities(show.medicine, 'clinic|hospital|dentist')"
                            />
                            <v-checkbox
                                v-model="show.restaurant"
                                :label="$t('headers.restaurants')"
                                class="font-weight-regular path-home_check justify-center align-center mt-0 black-color-text pt-1"
                                @click="handleAmenities(show.restaurant, 'restaurant')"
                            />
                            <v-checkbox
                                v-model="show.mall"
                                :label="$t('headers.shopping_centers')"
                                class="font-weight-regular path-home_check justify-center align-center mt-0 black-color-text pt-1"
                                @click="handleAmenities(show.mall, 'marketplace')"
                            />
                            <v-checkbox
                                v-model="show.mfc"
                                :label="$t('headers.multifunctional_center')"
                                class="font-weight-regular path-home_check justify-center align-center mt-0 black-color-text pt-1"
                                @click="handleAmenities(show.mfc, 'townhall')"
                            />
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="data_project.how_to_get_to">
                    <v-col>
                        <v-subheader class="main_color_text text-h6 font-weight-bold">
                            {{ $t('headers.how_to_get_to') }}
                        </v-subheader>
                        <p>{{ data_project.how_to_get_to }}</p>
                    </v-col>
                </v-row>

                <v-row v-if="data_project.infrastructure">
                    <v-col>
                        <v-subheader class="main_color_text text-h6 font-weight-bold">
                            {{ $t('headers.infrastructure') }}
                        </v-subheader>
                        <p>{{ data_project.infrastructure }}</p>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['data_project', 'project_address'],

    components: {
        OpenStreetMap: () => import('@/components/OpenStreetMap'),
        OpenStreetAutocomplete: () => import('@/components/OpenStreetAutocomplete'),
    },

    data () {
        return {
            routeTo: {
                latitude: null,
                longitude: null,
            },
            typeWayToGet: 'walking',
            project: {
                developer: null,
                apartments: [],
            },
            hours: 0,
            minutes: 0,
            show: {
                kindergarten: false,
                school: false,
                university: false,
                medicine: false,
                restaurant: false,
                mall: false,
                mfc: false,
            },
            path_home_map_height: 494,
        }
    },

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.width < 900
        },
        routeToIsReady() {
            return this.routeTo.latitude && this.routeTo.longitude
        }
    },

    mounted() {
        this.init()
    },

    methods: {
        init() {
            window.addEventListener("resize", this.pathHomeMapHeightSet);
            this.pathHomeMapHeightSet()
        },
        pathHomeMapHeightSet() {
            this.path_home_map_height = window.innerWidth > 1240 ? 494 : 250
        },
        setRouteTo(routeTo) {
            this.routeTo = routeTo
            this.handleCreateRoute()
        },
        handleCreateRoute() {
            if (!this.routeToIsReady) return

            this.$refs.detailsMap.getWayRouting(this.routeTo)
        },
        updateWayTime({ hours, minutes }) {
            this.hours = hours
            this.minutes = minutes
        },
        handleAmenities(isShow, type) {
            isShow
                ? this.$refs.detailsMap.getAmenities(type, this.data_project.name)
                : this.$refs.detailsMap.removeAmenities(type)
        }
    }
}
</script>

<style lang="scss" scoped>
.build_a_route {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    height: 62px !important;
    border-radius: 0;
    text-transform: none;
    width: 280px;
}

// Infrastructure
.wrap_radio {
    width: 250px;

    .v-input--radio-group__input {
        display: flex !important;
        justify-content: space-between !important;
    }
}

.path-home_check,
.path-home_check_mob
{
    border-radius: 0;
    width: 100%;
    color: #000000 !important;
    background: rgba(63, 68, 195, .1) !important;
    text-transform: none;
    font-size: 18px;
    height: 62px !important;
    padding-left: 10px !important;
    letter-spacing: 0;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 0;
    display: flex;
    align-items: flex-end;

    @media all and (max-width: 1240px) {
        height: 45px !important;
    }

    @media all and (max-width: 900px) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .v-input__control {
        .v-input__slot {
            margin-bottom: 15px !important;
        }
    }
}

.path-home_check_mob
{
    width: auto !important;
}

.path-home_check .v-input__control .v-input__slot
{
    margin-bottom: 15px !important;
}

.min-width100 {
    @media all and (max-width: 900px) {
        min-width: 100%;
    }
}

.max-width280 {
    max-width: 280px;
}

.margin-top0 {
    margin-top: 0 !important;
}

.max-width100 {
    max-width: 100%;
}
</style>
