<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
                Book
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5">
                Book a Call
            </v-card-title>
            <v-card-text>
                <div>
                    Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
                </div>
                <div v-if="showSuccess">
                    <v-sheet
                        class="d-flex"
                        color="success"
                        height="200"
                    >
                        We've received your request
                    </v-sheet>
                </div>
                <v-form v-else>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Phone" v-model="lead.name"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Email" v-model="lead.email"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Phone" v-model="lead.phone"/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    Disagree
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="saveLead"
                >
                    Agree
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Lead from "../../models/Lead";

export default {
    name: "LeadDialog",
    props: ['project_id', 'developer_id', 'bank_id'],
    data: function () {
        return {
            dialog: false,
            showSuccess: false,
            lead: {},
            name: '',
            email: '',
            phone: '',
        }
    },
    methods: {
        async saveLead() {
            let model = new Lead(this.lead)
            model = await model.save()
            if (model.id) {
                this.showSuccess = true
                setTimeout(() => this.dialog = false, 2000)
            }
        }
    },
    mounted() {
        let {project_id, developer_id, bank_id} = this
        this.lead = {project_id, developer_id, bank_id}
    }
}
</script>

<style scoped>

</style>
