<template>
    <v-row v-if="data_slider.length" class="pa-0">
        <v-col xs="9" sm="9" md="12" lg="8" xl="8" cols="12" class="order-2 order-md-1">
            <v-img :src="main_img_carousel" :height="main_img_carousel_height" max-width="610"/>

            <div class="img_small">
                <v-btn :height="img_small_height"
                       :width="img_small_width"
                       v-for="item in list_img"
                       :key="item.name"
                       @click="changeMainPhoto(item)"
                       class="overflow-hidden">
                    <v-img :src="item" :height="img_small_height" :width="img_small_width"
                           style="min-height: 100%"/>
                </v-btn>
            </div>
        </v-col>
        <v-col xs="9" sm="9" md="12" lg="4" xl="4" cols="12"
               class="order-1 order-md-2 pb-0 mb-md-0 mb-n2">
            <div class="wrap_card_list_btn">
                <v-card
                    class="transparent d-flex align-stretch flex-row flex-md-column card_list_btn"
                    elevation="0">
                    <v-btn depressed @click="defineArrImages('all', $event)"
                           class="font-weight-regular btn_carousel align-self-start justify-start "
                           id="active">{{ $t('headers.btn_slide_img_every') }}
                    </v-btn>
                    <v-btn v-for="category in categories.filter(category => this.data_slider.map(s => s.photo_category_id).includes(category.id))"
                           :key="category.id"
                           depressed
                           @click="defineArrImages(category, $event)"
                           class="font-weight-regular btn_carousel align-self-start justify-start "
                           >{{ category.name }}
                    </v-btn>
                </v-card>
            </div>
        </v-col>
    </v-row>
</template>

<script>

import ProjectPhotoCategory from "@/models/ProjectPhotoCategory";

export default {
    name: "SliderForProject",
    props: ['data_slider'],
    computed: {},
    data: function () {
        return {
            main_img_carousel_height: 432,
            list_img: [],
            img_arr: [],
            main_img_carousel: '/img/project.jpg',
            img_small_height: 142,
            img_small_width: 142,
            categories: []
        }
    },
    methods: {
        changeMainPhoto(photo) {
            this.main_img_carousel = photo;
        },

        defineArrImages(category, $event) {
            if (category === 'all') {
                this.list_img = this.data_slider
                    .map(photo => photo.image_url)
            } else {
                this.list_img = this.data_slider
                    .filter(photo => category.id === photo.photo_category_id)
                    .filter(photo => !!photo.image_url)
                    .map(photo => photo.image_url)
            }

            this.main_img_carousel = this.list_img[0];

            if ($event) {
                let btn_carousel = document.querySelectorAll('.btn_carousel')
                btn_carousel.forEach(i => i.removeAttribute('id'))
                $event.currentTarget.setAttribute('id', 'active')
            }
        },
        async init() {
            if (this.$vuetify.breakpoint.width > 900) {
                this.img_small_height = 124
                this.img_small_width = 142
            } else {
                this.img_small_height = 80
                this.img_small_width = 92
            }
        },
        main_img_carousel_height_set () {
            this.main_img_carousel_height = window.innerWidth > 900 ? 430 : 280
        }
    },
    async mounted() {
        this.init()
        window.addEventListener("resize", this.main_img_carousel_height_set)
        this.defineArrImages('general')
        this.categories = await ProjectPhotoCategory.get()
        this.defineArrImages('all')
    }
}
</script>

<style lang="scss" scoped>
.btn-fixed-price,
.build_a_route {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    height: 50px !important;
    width: 294px;
    border-radius: 0;
    text-transform: none;
}

.img_small {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: -10px;
    margin-left: -10px;

    @media all and (max-width: 900px) {
        margin-right: -5px;
        margin-left: -5px;
    }

    button {
        margin: 10px 0 0 10px;
        border-radius: 0 !important;

        @media all and (max-width: 900px) {
            margin: 5px 0 0 5px;
            height: 80px !important;
            width: 92px !important;
        }

        img {
            overflow: hidden;

            @media all and (max-width: 900px) {
                height: 80px !important;
                width: 92px !important;
            }
        }
    }
}

.fz-14 {
    font-size: 14px;
    letter-spacing: 0;
}

.lh-22 {
    line-height: 22px;
}

.bg_violet {
    background: rgba(63, 68, 196, 0.57) !important;
}

.wrap_card_list_btn {
    @media all and (max-width: 900px) {
        height: 45px; overflow: hidden;
    }

    .card_list_btn {
        @media all and (max-width: 900px) {
            overflow: auto; height: 90px; border-radius: 0;
        }
    }
}

.btn_carousel {
    border-radius: 0;
    width: 100%;
    text-align: left !important;
    color: #000000;
    background: rgba(63, 68, 195, .1) !important;
    text-transform: none;
    font-size: 18px;
    height: 62px !important;
    padding-left: 23px !important;
    letter-spacing: 0;
    margin-bottom: 10px;

    @media all and (max-width: 900px) {
        padding-left: 20px !important;
    }

    span.v-btn__content {
        max-width: 100% !important;

        @media all and (max-width: 900px) {
            width: auto;
            text-align: center;
        }
    }

    @media all and (max-width: 1240px) {
        height: 45px !important;
    }

    @media all and (max-width: 900px) {
        width: auto;
        margin-right: 5px;
        text-align: center;
    }
}

#active {
    background: #3F44C3 !important;
    color: #ffffff;
}
</style>
