<template>
    <div :id="id" :style="{height: height+'px', 'z-index': 150}" />
</template>

<script>
import L from 'leaflet'
import Api from '@/api/Api'
import 'leaflet-routing-machine'
import { geoPointFormat } from '@/utils'

const MAPBOX_DIRECTIONS_API = 'https://api.mapbox.com/directions/v5'
const MAPBOX_ACCESS_API = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'

export default {
    props: ['lat', 'lng', 'zoom', 'id', 'height', 'typeWay'],

    data () {
        return {
            map: {},
            routes: [],
        }
    },

    mounted() {
        this.mapInit()
    },

    methods: {
        mapInit() {
            this.map = L.map(this.id).setView([this.lat, this.lng], this.zoom || 10);

            L.tileLayer(MAPBOX_ACCESS_API, {
                id: 'mapbox/streets-v11',
                maxZoom: 18,
                tileSize: 512,
                zoomOffset: -1,
                accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
            }).addTo(this.map);

            L.marker([this.lat, this.lng]).addTo(this.map).bindPopup('address')
        },
        getWayRouting({ latitude, longitude }) {
            this.routes.forEach(route => this.map.removeControl(route))

            const control = L.Routing.control({
                waypoints: [
                    L.latLng(this.lat, this.lng),
                    L.latLng(latitude, longitude)
                ],
                router: L.Routing.mapbox(process.env.VUE_APP_MAPBOX_ACCESS_TOKEN, {
                    profile: `mapbox/${this.typeWay}`,
                    urlParameters: {
                        serviceUrl: MAPBOX_DIRECTIONS_API,
                    }
                }),
                fitSelectedRoutes: true,
            }).addTo(this.map)

            this.routes.push(control)

            control.on('routeselected', (e) => {
                const length = (e.route.summary.totalDistance / 1000).toFixed(2)
                const minsFoot = this.typeWay === 'walking' ? length / 5 * 60 : length / 40 * 60

                this.$emit('update-way-time', {
                    hours: Math.trunc(minsFoot / 60),
                    minutes: (minsFoot % 60).toFixed()
                })
            })
        },
        async getAmenities(type = 'restaurant', name) {
            // делаем пределы для меток, относительно нахождения новостройки
            const minlat = geoPointFormat(parseFloat(this.lat) - 0.005)
            const maxlat = geoPointFormat(parseFloat(this.lat) + 0.005)
            const minlon = geoPointFormat(parseFloat(this.lng) - 0.005)
            const maxlon = geoPointFormat(parseFloat(this.lng) + 0.005)

            // делаем пост запрос на контроллер, чтобы получить маркеры
            const { data } = await Api.getMarkers({
                minlat, maxlon, maxlat, minlon, type, name
            })

            // прохожусь по каждому маркеру из массива маркеров
            data.forEach(element => {
                if (!element.lat || !element.lon) return

                // создаем маркер с типом и добавляем на карту
                const marker = L.marker([element.lat, element.lon]).addTo(this.map).bindPopup(element.tags.name || '')
                marker.type = type
            })
        },
        // перебираем слои карты и удаляем маркеры определенного типа
        removeAmenities(type) {
            this.map.eachLayer(marker => {
                if (marker.type === type) {
                    this.map.removeLayer(marker)
                }
            })
        }
    }
}
</script>

<style>
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");

div.leaflet-top.leaflet-right {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}
</style>
